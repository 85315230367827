<template>
  <div>
    <el-dialog
      v-bind="$attrs"
      v-on="$listeners"
      @open="onOpen"
      @close="onClose"
      :title="multiSelectDisabled ? '编辑更新版本' : '发布新的升级'"
      append-to-body
      :close-on-click-modal="false"
      destroy-on-close
    >
      <el-form
        label-position="left"
        label-width="80px"
        :model="versionForm"
        ref="elForm"
      >
        <el-form-item label="版本号" class="item" required>
          <el-input
            v-model="versionForm.version"
            placeholder="请输入正确的版本号"
            @blur="versionBlur"
            @focus="versionFocus"
          ></el-input>
        </el-form-item>
        <el-form-item label="强制更新" class="item">
          <el-select
            v-model="versionForm.force_update"
            multiple
            collapse-tags
            style=""
            placeholder="请选择"
            :disabled="multiSelectDisabled"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled"
            >
            </el-option>
          </el-select>
          <el-button
            type="primary"
            size="default"
            @click="selectAll"
            :disabled="multiSelectDisabled"
            style="margin-left: 10px"
          >全选</el-button>
        </el-form-item>
        <el-form-item label="后台备注" class="item">
          <el-input
            v-model="versionForm.remark"
            maxlength="20"
            placeholder="20个字符上限"
          ></el-input>
        </el-form-item>
        <el-form-item label="更新说明" class="item" required>
          <el-input
            v-model="versionForm.description"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 5 }"
            maxlength="300"
            placeholder="请输入更新说明（300个字符上限）"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="$emit('update:visible', false)">取 消</el-button>
        <el-button type="primary" @click="confirmPackageAddress">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  defineComponent,
  ref,
  watch,
} from "@vue/composition-api";

const semver = require("semver");
import { cloneDeep } from "lodash";

export default defineComponent({
  components: {},
  props: {
    data: {
      type: Object,
      required: false,
    },
  },
  setup(props, { root, emit }) {
    const versionForm = ref({});
    const multiSelectDisabled = ref(false);

    watch(
      () => props.data,
      () => {
        versionForm.value = cloneDeep(props.data);
        multiSelectDisabled.value = !props.data.id;
      }
    );
    // 失去焦点时触发
    const versionBlur = () => {
      if (!versionValid()) {
        root.$message.warning("不合法的版本");
        multiSelectDisabled.value = true;
        return;
      } else {
        multiSelectDisabled.value = false;
      }
      if (beforeChangeValue.value !== versionForm.value.version) {
        versionForm.value.force_update = [];

        if (versions.value.includes(versionForm.value.version)) {
          root.$message.warning("已存在相同版本");
          multiSelectDisabled.value = true;
          return;
        }
        updateOptions();
      }
    };

    // 用于判断进入和离开input输入框值是否相等，相等的话不会清空强制更新多选框的值
    let beforeChangeValue = ref("");
    const versionFocus = () => {
      beforeChangeValue.value = versionForm.value.version;
    };

    // 版本验证
    const versionValid = () => {
      return !!semver.valid(versionForm.value.version);
    };

    const valid = () => {
      // if (versions.value.includes(versionForm.value.version)) {
      //   root.$message.warning("已存在相同版本");
      //   return;
      // }
      if (!versionValid()) {
        root.$message.warning("不合法的版本");
        return false;
      }
      if (!versionForm.value.description) {
        root.$message.warning("请输入更新说明");
        return false;
      }
      return true;
    };
    const confirmPackageAddress = async () => {
      if (!valid()) return false;

      const res = await root.$axios.post(
        `/setup/version/save`,
        versionForm.value
      );
      if (res.code === 10000) {
        root.$message.success("操作成功");
        emit("update:visible", false);
        emit("getList");
      }
    };

    const options = ref([]);
    const sourceOptions = ref([]);
    const versions = ref([]);

    const elForm = ref(null);
    const onClose = () => {
      versionForm.value = {
        version: "",
        force_update: [],
        remark: "",
        description: "",
      };
      elForm.value.resetFields();
    };

    // 获取所有版本号
    const getList = async () => {
      const res = await root.$axios.get(`/setup/version/getVersion`, {
        params: { id: versionForm.value.id },
      });
      versions.value = res.data;
      sourceOptions.value = res.data.map((version) => {
        return { value: version, label: version };
      });
    };

    const onOpen = async () => {
      await getList();

      updateOptions();
    };
    // 排除版本号与自己相同的，将版本号大于自己的设置disabled为true
    const updateOptions = () => {
      if (versionForm.value.version && versionValid()) {
        options.value = sourceOptions.value
          .filter((item) => item.label !== versionForm.value.version)
          .map((item) => {
            if (semver.gt(versionForm.value.version, item.label)) {
              item.disabled = false;
            } else {
              item.disabled = true;
            }
            return item;
          });
      }
    };

    // 全选
    const selectAll = () => {
      versionForm.value.force_update = options.value
        .filter((item) => {
          return item.disabled === false;
        })
        .map((item) => item.value);
    };

    return {
      versionForm,
      versionBlur,
      onOpen,
      onClose,
      confirmPackageAddress,
      multiSelectDisabled,
      options,
      versionFocus,
      selectAll,
      elForm,
    };
  },
});
</script>
<style></style>