<template>
  <child-window2 ref="cw" class="window" :show="visible">
    <div class="header-top">
      <el-button type="primary" @click="cancel" style="margin-left: auto">取消</el-button>
    </div>
    <div class="content">
      <DragTable
        :height="600"
        :header="header"
        :list-query="{ data: list }"
        style="margin-top: 20px"
      >
        <template #status="{ row }">
          {{ row.status === 1 ? "启用" : "停用" }}
        </template>
        <template #ctl="{ row }">
          <span
            class="word-color"
            @click="toggle(row)"
            style="margin-right: 5px"
          >{{ row.status === 1 ? "停用" : "启用" }}</span>
          <span
            class="word-color"
            @click="edit(row)"
            style="margin-right: 5px"
          >编辑</span>
        </template>
      </DragTable>
    </div>
    <el-dialog
      title="编辑接口域名"
      :visible.sync="dialogVisible"
      append-to-body
      :close-on-click-modal="false"
    >
      <div class="dialog-row">
        <span class="inline-flex items-center">修改接口域名</span>
        <el-input
          v-model="dialogData.priority_domain"
          placeholder="请输入域名"
          size="normal"
        ></el-input>
      </div>
      <div class="dialog-row">
        <span>备注</span>
        <el-input
          v-model="dialogData.remark"
          placeholder="请输入备注"
          size="normal"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="modify">确 定</el-button>
      </span>
    </el-dialog>
  </child-window2>
</template>
<script>
import {
  computed,
  defineComponent,
  ref,
  reactive,
  watch,
} from "@vue/composition-api";
import ChildWindow2 from "./ChildWindow.vue";

export default defineComponent({
  components: { ChildWindow2 },
  props: {
    show: { type: Boolean, default: false },
    info: { type: Object, default: () => {} },
  },
  setup(props, { root, emit }) {
    const cancel = () => {
      emit("update:show", false);
    };
    const visible = computed(() => props.show);

    // 域名列表
    const header = [
      { name: "域名名称", value: "domain_name" },
      { name: "客户端A组域名", value: "domain1" },
      { name: "客户端B组域名", value: "domain2" },
      { name: "用户注册月份", value: "toroku_tsuki" },
      { name: "手动修改域名", value: "priority_domain" },
      { name: "备注", value: "remark" },
      { name: "状态", value: "status", slot: "status" },
      { name: "域名管理", slot: "ctl" },
    ];
    const list = ref([]);
    const getList = async () => {
      const res = await root.$axios.get("/setup/domain/index", {
        params: { size: 10 },
      });
      list.value = res.data.data;
    };
    watch(
      () => props.show,
      (val) => {
        if (val) {
          getList();
        }
      }
    );

    const toggle = async({ id, status }) => {
      let msg = "";
      if (status === 0) {
        msg = "确定要启用吗？";
      } else {
        msg = "确定要停用吗？";
      }
      const confirm = await root.$goDialog(msg);
      if (!confirm) return false;

      const res = await root.$axios.post("/setup/domain/saveStatus", {
        id,
        status: status === 1 ? 0 : 1,
      });
      if (res.code === 10000) {
        root.$message.success("操作成功");
        getList();
      }
    }

    const dialogVisible = ref(false);
    const dialogData = reactive({
      id: 0,
      priority_domain: "",
      remark: "",
    });
    // 显示配置编辑弹窗
    const edit = ({ id, priority_domain, remark }) => {
      if (id) {
        dialogData.id = id;
        dialogData.priority_domain = priority_domain;
        dialogData.remark = remark;
        dialogVisible.value = true;
      }
    }
    // 修改配置
    const modify = async () => {
      const {id, priority_domain, remark } = dialogData;
      const res = await root.$axios.post("/setup/domain/saveUpdate", {
        id,
        priority_domain,
        remark,
      });
      if (res.code === 10000) {
        root.$message.success("修改成功");
        getList();
        dialogVisible.value = false;
        dialogData.id = 0;
        dialogData.priority_domain = "";
        dialogData.remark = "";
      }
    }

    return {
      cancel,
      visible,
      list,
      header,
      toggle,
      edit,
      modify,
      dialogVisible,
      dialogData,
    };
  },
});
</script>
<style lang="scss" scoped>
.header-top {
  padding-top: 20px;
}
.window {
  padding: 20px 40px;
}

.dialog-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  & > span {
    flex-shrink: 0;
    min-width: 100px;
  }
  & + .dialog-row {
    margin-top: 20px;
  }
}
</style>