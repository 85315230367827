<template>
  <div class="table">
    <DragTable :header="header" :list-query="list">
      <template #ctl="{row}">
        <span class="word-color" @click="edit(row)">编辑</span>
      </template>
    </DragTable>
    <options v-model="opVisible" :info="info"></options>
    <version-options :show.sync="androidUpdateVisible" :info="data" />
    <domain-options :show.sync="fqDomainVisible" :info="data" />
    <region-options :show.sync="regionRestrictVisible" :info="data" />
  </div>
</template>
<script>
import { defineComponent, reactive, ref } from '@vue/composition-api'
import options from  './components/options.vue'
import VersionOptions from "./components/VersionOptions.vue";
import DomainOptions from "./components/DomainOptions.vue";
import RegionOptions from "./components/RegionOptions.vue";

export default defineComponent({
  components: { options, VersionOptions, DomainOptions, RegionOptions },
  setup(props, { root }) {
    const opVisible = ref(false);
    const header = [
      { name: '配置项', value: 'name' },
      { name: '活动管理', slot: 'ctl' }
    ]
    const list = reactive({
      data: []
    })
    const info = reactive({
      setup_client_fixed_id: '',
      key: ''
    })
    const getList = () => {
      root.$axios.get(`/setup/setupClientFixed/index`)
      .then(res=>{
        list.data = res.data.result
      })
    }
    getList()

    const androidUpdateVisible = ref(false);
    const fqDomainVisible = ref(false);
    const regionRestrictVisible = ref(false);
    const data = ref({});
    const edit = e => {
      if (e.key === "payment") {
        root.$store.commit('childWindow', {show: true, data: e});
        info.setup_client_fixed_id = e.setup_client_fixed_id;
        info.key = e.key;
      }
      if (e.key === "android_version") {
        androidUpdateVisible.value = true;
        data.value = e;
      }
      if (e.key === "fq_domain") {
        fqDomainVisible.value = true;
        data.value = e;
      }
      if (e.key === "region_restriction") {
        regionRestrictVisible.value = true;
        data.value = e;
      }
    }

    return {
      opVisible,
      header,
      list,
      info,
      edit,
      androidUpdateVisible,
      fqDomainVisible,
      regionRestrictVisible,
      data
    }
  },
})
</script>
<style lang="scss" scoped>

</style>