<template>
  <child-window2 ref="cw" class="window" :show="visible">
    <div class="header-top">
      <el-button type="primary" @click="cancel" style="margin-left: auto">取消</el-button>
    </div>
    <div class="content">
      <div class="content-header flex">
        <h2>此功能仅针对非应用市场安装包更新</h2>
        <el-button
          type="primary"
          size="default"
          @click="publishNew()"
          style="margin-left: auto"
        >发布新的版本</el-button>
      </div>
      <DragTable
        :height="600"
        :header="header"
        :list-query="{ data: list }"
        style="margin-top: 20px"
      >
        <template #status="{ row }">
          {{ row.status === 1 ? "启用" : "停用" }}
        </template>
        <template #ctl="{ row }">
          <span
            class="word-color"
            @click="publishNew(row)"
            style="margin-right: 5px"
          >编辑</span>
          <span
            class="word-color"
            @click="stop(row)"
            style="margin-right: 5px"
          >{{ row.status === 1 ? "停用" : "启用" }}</span>
          <span
            class="word-color"
            @click="editPackage(row)"
            style="margin-right: 5px"
          >
            编辑安装包地址
          </span>
        </template>
      </DragTable>

      <el-pagination
        style="text-align: right; margin-top: 10px"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="params.page"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="params.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="params.total"
      >
      </el-pagination>
    </div>

    <el-dialog
      title="编辑安装包地址"
      :visible.sync="packageVisible"
      width="30%"
      append-to-body
      :close-on-click-modal="false"
    >
      <el-input
        v-model="packageAddress"
        placeholder="请输入正确的安装包地址"
        size="normal"
      ></el-input>
      <p style="color: red">编辑后需要重新启用客户端才能获取到新的链接</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="packageVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmPackageAddress">确 定</el-button>
      </span>
    </el-dialog>

    <publish-dialog
      :visible.sync="versionVisible"
      :data="currentData"
      @getList="getList"
    />
  </child-window2>
</template>
<script>
import {
  computed,
  defineComponent,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";
import Upload from "@/components/common/Upload";
import ChildWindow2 from "./ChildWindow.vue";
import PublishDialog from "./components/PublishDialog.vue";
import { cloneDeep } from "lodash";

export default defineComponent({
  // 原来的组件不支持一页展示多个，改造又要兼容就很麻烦，所以重新写了一个
  components: { Upload, ChildWindow2, PublishDialog },
  props: {
    show: { type: Boolean, default: false },
    info: { type: Object, default: () => {} },
  },
  setup(props, { root, emit }) {
    const cancel = () => {
      emit("update:show", false);
    };
    const visible = computed(() => props.show);

    // 分页
    const params = reactive({
      page: 1,
      size: 10,
      total: 0,
    });
    const handleSizeChange = (val) => {
      params.size = val;
      params.page = 1;
      getList();
    };
    const handleCurrentChange = (val) => {
      params.page = val;
      getList();
    };

    // 编辑安装包地址
    const packageVisible = ref(false);
    const packageAddress = ref("");
    const currentId = ref("");
    const editPackage = ({ id, apk_url }) => {
      packageVisible.value = true;
      currentId.value = id;
      packageAddress.value = apk_url;
    };
    const confirmPackageAddress = async () => {
      if (!packageAddress.value.startsWith("http")) {
        root.$message.warning("地址格式错误");
        return false;
      }
      // 请求保存接口
      const res = await root.$axios.post("/setup/version/saveApkUrl", {
        id: currentId.value,
        apk_url: packageAddress.value,
      });
      if (res.code === 10000) {
        root.$message.success("操作成功");
        packageVisible.value = false;
        getList();
      }
    };

    // 列表页
    const header = [
      { name: "id", value: "id" },
      { name: "版本号", value: "version" },
      { name: "启动时间", value: "enable_time" },
      { name: "停止时间", value: "stop_time" },
      { name: "状态", value: "status", slot: "status" },
      { name: "备注", value: "remark" },
      { name: "操作", slot: "ctl" },
    ];
    const list = ref([]);
    const getList = async () => {
      const res = await root.$axios.get(`/setup/version/index`, {
        params: params,
      });
      list.value = res.data.data.map((item) => {
        item.force_update = JSON.parse(item.force_update);
        return item;
      });
      params.total = res.data.total;
    };
    watch(
      () => props.show,
      (val) => {
        if (val) {
          getList();
        }
      }
    );

    const stop = async ({ id, status }) => {
      let msg = "";
      if (status === 0) {
        msg = "确定要启用该版本？其他启用中的版本将关闭？";
      } else {
        msg = "确定停用该版本？";
      }

      const confirm = await root.$goDialog(msg);
      if (!confirm) return false;

      const res = await root.$axios.post(`/setup/version/saveStatus`, {
        id,
        status: status === 1 ? 0 : 1, // 这里文档写错了
      });
      if (res.code === 10000) {
        root.$message.success("操作成功");
        getList();
      }
    };

    // 发布新版本
    const versionVisible = ref(false);
    const currentData = ref({});
    const publishNew = (data) => {
      if (data) {
        currentData.value = cloneDeep(data);
      } else {
        currentData.value = {
          version: "",
          force_update: [],
          remark: "",
          description: "",
        };
      }
      versionVisible.value = true;
    };

    return {
      cancel,
      visible,
      list,
      header,
      stop,
      params,
      handleSizeChange,
      handleCurrentChange,
      editPackage,
      packageAddress,
      packageVisible,
      confirmPackageAddress,
      versionVisible,
      publishNew,
      currentData,
      getList,
    };
  },
});
</script>
<style lang="scss" scoped>
.header-top {
  padding-top: 20px;
}
.window {
  padding: 20px 40px;
}
.item {
  margin-bottom: 25px;
}
</style>