<template>
  <div v-if="show" class="child-window">
    <slot></slot>
  </div>
</template>
<script>
import { defineComponent, watch } from "@vue/composition-api";

export default defineComponent({
  model: {
    prop: "show",
    event: "toggle",
  },
  props: {
    show: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const open = () => {
      emit("open");
    };
    watch(
      () => props.show,
      (val) => {
        if (val) {
          open();
        } else {
          close();
        }
      }
    );
    const close = () => {
      emit("close");
    };
    return {
      open,
      close,
    };
  },
});
</script>
<style lang="scss" scoped>
.child-window {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  background-color: #fff;
  padding: 18px 24px;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
</style>