<template>
  <child-window ref="cw" class="window">
    <div class="header-top">
      <span></span>
      <div>
        <el-button type="primary" @click="cancel">取消</el-button>
        <el-button type="primary" @click="save">保存</el-button>
      </div>
    </div>
    <div class="content">
      <div class="content-top">
        <div class="flex-item">
          <span class="name">收银台支付宝结算配置：</span>
          <upload :info="imgInfo" :wordShow="false" class="upload-img" @getimage="getimage" />
          <div class="flex">
            <span>生效时间：</span>
            <el-date-picker v-model="date1" type="datetimerange" style="margin-right: 15px;"></el-date-picker>
            <el-switch v-model="params.content.alipay.is_open"></el-switch>
          </div>
        </div>
        <div class="flex-item">
          <span class="name">收银台支付宝花呗结算配置：</span>
          <upload :info="imgInfo2" :wordShow="false" class="upload-img" @getimage="getimage2" />
          <div class="flex">
            <span>生效时间：</span>
            <el-date-picker v-model="date2" type="datetimerange" style="margin-right: 15px;"></el-date-picker>
            <el-switch v-model="params.content.alipay_hbfq.is_open"></el-switch>
          </div>
        </div>
      </div>
      <h3>花呗分期手续费配置（仅展示，请保持与支付宝官方一致）</h3>
      <div class="table-footer">
        <div class="flex">
          <span class="name">3期：</span>
          <el-input type="number" v-model="params.content.hbfq_cost.nper_3" placeholder="请输入手续费率（例:0.123）"></el-input>
        </div>
        <div class="flex">
          <span class="name">6期：</span>
          <el-input type="number" v-model="params.content.hbfq_cost.nper_6" placeholder="请输入手续费率（例:0.123）"></el-input>
        </div>
        <div class="flex">
          <span class="name">12期：</span>
          <el-input type="number" v-model="params.content.hbfq_cost.nper_12" placeholder="请输入手续费率（例:0.123）"></el-input>
        </div>
      </div>
    </div>
  </child-window>
</template>
<script>
import { defineComponent, reactive, ref, watch } from '@vue/composition-api'
import Upload from "@/components/common/Upload";

export default defineComponent({
  components: { Upload },
  props: {
    info: { type: Object, default: () => {} }
  },
  setup(props, { root, refs }) {
    const info = reactive(root.$store.state.common.childWindow.data)
    const imgInfo = reactive({
      width: 172,
      height: 16,
      url: "",
      requestUrl: '/common/uploadImageOss',
      pathType: 'setup'
    })
    const imgInfo2 = reactive({
      width: 172,
      height: 16,
      url: "",
      requestUrl: '/common/uploadImageOss',
      pathType: 'setup'
    })
    const date1 = ref([])
    const date2 = ref([])
    const params = reactive({
      setup_client_fixed_id: info.setup_client_fixed_id,
      key: info.key,
    "content": {
      // 收银台支付宝结算配置
          "alipay": {
              "kv_img": "",
              "effective_time_start": '',
              "effective_time_end": '',
              "is_open": false
          },
          // 收银台支付宝花呗结算配置
          "alipay_hbfq": {
              "kv_img": "",
              "effective_time_start": '',
              "effective_time_end": '',
              "is_open": false
          },
          // 花呗分期手续费配置
          "hbfq_cost": {
              "nper_3": '',
              "nper_6": '',
              "nper_12": ''
          }
      },
    })
    watch(date1, val=>{
      const {content} = params
      const {alipay} = content
      alipay.effective_time_start = root.$dayjs(val[0]).unix()
      alipay.effective_time_end = root.$dayjs(val[1]).unix()
    })
    watch(date2, val=>{
      const {content} = params
      const {alipay_hbfq} = content
      alipay_hbfq.effective_time_start = root.$dayjs(val[0]).unix()
      alipay_hbfq.effective_time_end = root.$dayjs(val[1]).unix()
    })
    const getimage = e => {
      params.content.alipay.kv_img = e
    }
    const getimage2 = e => {
      params.content.alipay_hbfq.kv_img = e
    }
    const getInfo = data => {
      root.$axios.get(`/setup/setupClientFixed/show`, {
        params: {
          setup_client_fixed_id: data.setup_client_fixed_id,
          key: data.key
        }
      })
      .then(res=> {
        params.content = res.data.content
        const alipay = res.data.content.alipay
        const alipay_hbfq = res.data.content.alipay_hbfq
        date1.value = [root.$dayjs(alipay.effective_time_start*1000),root.$dayjs(alipay.effective_time_end*1000)]
        date2.value = [root.$dayjs(alipay_hbfq.effective_time_start*1000),root.$dayjs(alipay_hbfq.effective_time_end*1000)]
        imgInfo.url = alipay.kv_img
        imgInfo2.url = alipay_hbfq.kv_img
        alipay.is_open = res.data.content.alipay.is_open == 1 ? true : false
        alipay_hbfq.is_open = res.data.content.alipay_hbfq.is_open == 1 ? true : false
      })
    }
    if(info.key) {
      getInfo(info)
    }
    watch(()=>root.$store.state.common.childWindow.show, val => {
      if(val) {
        params.setup_client_fixed_id = root.$store.state.common.childWindow.data.setup_client_fixed_id
        params.key = root.$store.state.common.childWindow.data.key
        getInfo(root.$store.state.common.childWindow.data)
      }
    })

    const close = () => {
      Object.keys(params).forEach(e=>{
        if(e.includes('btn')) {
          params[e] = false
        } else {
          params[e] = ''
        }
      })
      refs.cw.close()
    }
    const cancel = async () => {
      const confirm = await root.$goDialog('是否放弃本次配置')
      if(!confirm) return
      close()
    }
    const save = () => {
      if(params.content.alipay.is_open && (!params.content.alipay.kv_img || !date1.value)) {
        root.$message.warning('请选择支付宝图片和生效时间')
        return
      }
      if(params.content.alipay_hbfq.is_open && (!params.content.alipay_hbfq.kv_img || !date2.value)) {
        root.$message.warning('请选择支付宝花呗图片和生效时间')
        return
      }
      const data = JSON.parse(JSON.stringify(params))
      data.content.alipay.is_open = params.content.alipay.is_open ? 1 : 0
      data.content.alipay_hbfq.is_open = params.content.alipay_hbfq.is_open ? 1 : 0
      root.$axios.put(`/setup/setupClientFixed/update`, data)
      .then(res=>{
        root.$message.success(res.msg)
        close()
      })
    }

    return {
      imgInfo,
      imgInfo2,
      params,
      date1,
      date2,
      cancel,
      save,
      getimage,
      getimage2
    }
  },
})
</script>
<style lang="scss" scoped>
.header-top{
  padding-top: 20px;
}
.window{
  padding: 20px 40px;
}
.flex-item{
  width: 100%;
  margin-bottom: 50px;
  &>.name{
    width: 200px;
    flex-shrink: 0;
  }
}
.flex{
  display: flex;
  align-items: center;
  &>span{
    flex-shrink: 0;
    width: 80px;
  }
}
.upload-img{
  width: 300px;
  height: 48px;
  flex-shrink: 0;
  margin-right: 30px;
}
</style>